import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import '@mantine/core/styles.css';
import { json, LinksFunction, LoaderFunction } from "@remix-run/node";
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData, useRouteError } from "@remix-run/react";
import { MetaFunction } from "@vercel/remix";
import { ColorSchemeScript, TypographyStylesProvider, MantineProvider } from '@mantine/core';
import '@mantine/carousel/styles.css';
import { SpeedInsights } from "@vercel/speed-insights/remix";
import { theme } from './lib/theme';
import { Analytics } from "@vercel/analytics/react";
import { ConfigProvider } from "./lib/config-context";
import { getConfig } from "./services/config.server";
import ErrorBoundaryComponent from "./components/ErrorBoundary";

export const links: LinksFunction = () => {
  return [
    { rel: "stylesheet", href: "/app.css" },
  ];
}

export const meta: MetaFunction = () => {
  return [
    { title: "Special Offer from Baltimore Home Cleaning" },
    { name: "description", content: "Professional cleaning services at unbeatable prices. Book your clean today!" },
  ];
};

export const loader: LoaderFunction = async () => {
  const config = await getConfig();

  // console.log('Loaded config:', config);

  return json({
    ENV: {
      NODE_ENV: process.env.NODE_ENV,
    },
    config,
  }, {
    headers: {
      'Cache-Control': 'public, max-age=60', // Cache for 1 minute
    },
  });
};

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <ColorSchemeScript />
        <meta name="robots" content="noindex" />
      </head>
      <body>
        <MantineProvider theme={theme}>
          <TypographyStylesProvider>
            {children}
            <ScrollRestoration />
            <Scripts />
            <Analytics />
            <SpeedInsights />
          </TypographyStylesProvider>
        </MantineProvider>
      </body>
    </html>
  );
}

function App() {
  const { config } = useLoaderData<typeof loader>();

  return (
    <ConfigProvider initialConfig={config}>
      <Outlet />
    </ConfigProvider>
  );
}

export default withSentry(App);

export function ErrorBoundary() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  // If this is a document error, return the full HTML structure
  if (error instanceof Error && error.message.includes('document')) {
    return (
      <html lang="en">
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <Meta />
          <Links />
          <ColorSchemeScript />
          <meta name="robots" content="noindex" />
        </head>
        <body>
          <MantineProvider theme={theme}>
            <TypographyStylesProvider>
              <ErrorBoundaryComponent error={error} />
              <ScrollRestoration />
              <Scripts />
              <Analytics />
              <SpeedInsights />
            </TypographyStylesProvider>
          </MantineProvider>
        </body>
      </html>
    );
  }

  // For other errors, just render the error component
  return (
    <ErrorBoundaryComponent error={error instanceof Error ? error : undefined} />
  );
}